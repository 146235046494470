<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="login-box" :style="{ height: height }">
        <img src="https://www.achencybe.com/img/pc/login/5-1.jpg" style="width: 600px"/>
        <div class="form-box">
          <img src="https://www.achencybe.com/img/pc/logo_1.png"/>
          <div class="text-box">
            <div class="title">{{ $t('register_page.register_title') }}</div>
            <div>{{ $t('register_page.register_logon') }}</div>
          </div>
          <div class="tabs-box">
            <span @click="active = 0" :class="{ active: active === 0 }"
            >{{ $t('register_page.mail_register') }}</span
            >
            <span @click="active = 1" :class="{ active: active === 1 }"
            >{{ $t('register_page.phone_register') }}</span
            >
            <span
                class="border-line"
                :style="{ left: active === 0 ? 0 : '50%' }"
            ></span>
          </div>

          <el-form
              ref="phoneForm"
              key="phoneForm"
              :rules="phoneRules"
              :model="phoneForm"
              style="width: 330px"
              v-if="active === 1"
          >
            <el-form-item prop="phone">
              <zui-country-phone :sendSms="true"
                                 :placeholder="$t('register_page.login_phone_placeholder')"
                                 v-model="phoneForm.phone"
                                 v-on:listenToChildEvent="showRealNumber"
                                 v-on:listenSendSms="takeCode"
                                 :limit-seconds=seconds
              ></zui-country-phone>
              <!--              <el-input v-model="phoneForm.phone" :placeholder="$t('register_page.login_phone_placeholder')">-->
              <!--                <template slot="append">-->
              <!--                  <span v-show="seconds != 0">{{$t('register_page.retry_begin')}}{{ seconds }}{{$t('register_page.retry_end')}}</span>-->
              <!--                </template>-->
              <!--              </el-input>-->
              <!--              <el-button class="append" v-show="seconds == 0" @click="takeCode"-->
              <!--              >{{$t('login_and_register_page.verification_code')}}-->
              <!--              </el-button-->
              <!--              >-->
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  v-model="phoneForm.password"
                  type="password"
                  :placeholder="$t('register_page.login_mail_password_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="vcode">
              <el-input
                  v-model="phoneForm.vcode"
                  :placeholder="$t('login_and_register_page.verification_code_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="subscribe">
              <el-checkbox v-model="phoneForm.subscribe"
              >{{ $t('register_page.subscribe') }}
                <el-link
                    style="margin-top: -5px"
                    @click.native.stop="$router.push({ name: 'clausePage' })"
                >{{ $t('register_page.subscribe_link') }}
                </el-link
                >
              </el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  style="width: 100%"
                  @click="submitForm('phoneForm')"
              >{{ $t('register_page.submit') }}
              </el-button
              >
              <br/>
              <el-link
                  type="primary"
                  @click="$router.push({ name: 'LoginPage' })"
              >{{ $t('register_page.login') }}
              </el-link
              >
            </el-form-item>
          </el-form>
          <el-form
              ref="emailForm"
              key="emailForm"
              :rules="emailRules"
              :model="emailForm"
              style="width: 330px"
              v-if="active === 0"
          >
            <el-form-item prop="email">
              <el-input
                  v-model="emailForm.email"
                  :placeholder="$t('register_page.login_mail_address_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  v-model="emailForm.password"
                  type="password"
                  :placeholder="$t('register_page.login_mail_password_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="againPassword">
              <el-input
                  v-model="emailForm.againPassword"
                  type="password"
                  :placeholder="$t('register_page.login_mail_password_placeholder_again')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="subscribe">
              <el-checkbox v-model="emailForm.subscribe"
              >{{ $t('register_page.subscribe') }}
                <el-link
                    style="margin-top: -5px"
                    @click.native.stop="$router.push({ name: 'clausePage' })"
                >{{ $t('register_page.subscribe_link') }}
                </el-link
                >
              </el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  style="width: 100%"
                  @click="submitForm('emailForm')"
              >{{ $t('register_page.submit') }}
              </el-button
              >
              <br/>
              <el-link
                  type="primary"
                  :underline="false"
                  @click="$router.push({ name: 'LoginPage' })"
              >{{ $t('register_page.login') }}
              </el-link
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiCountryPhone from "@/components/CountryPhone.vue";

export default {
  components: {ZuiCountryPhone, Page, ZuiHeader,},
  computed: {

    emailRules() {
      const validatePwd = (rule, value, callback) => {
        if (!value) {
          callback(new Error(window.vm.$t('login_and_register_page.input_password_again')));
        } else if (value !== this.emailForm.password) {
          callback(new Error(window.vm.$t('login_and_register_page.input_password_discord')));
        } else {
          callback();
        }
      };
      return {
        email: [
          {required: true, message: window.vm.$t('login_and_register_page.input_email'), trigger: "blur"},
          {
            pattern:
                /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
            message: window.vm.$t('login_and_register_page.input_email_uncheck'),
            trigger: "blur",
          },
        ],
        password: [{required: true, message: window.vm.$t('login_and_register_page.input_password'), trigger: "blur"}],
        againPassword: [
          {required: true, message: window.vm.$t('login_and_register_page.input_password_again'), trigger: "blur"},
          {validator: validatePwd, trigger: "blur"},
        ],
        subscribe: [
          {
            required: true,
            message: window.vm.$t('login_and_register_page.read_terms'),
            trigger: "change",
          },
        ],
      }
    },
    phoneRules() {
      return {
        phone: [
          {
            required: true, message: window.vm.$t('login_and_register_page.input_phone'), trigger: "blur"
          },
          {
            pattern: this.realNumber.startsWith('+86') ? /^1[3-9]\d{9}$/ : /\d+/,
            message: window.vm.$t('login_and_register_page.input_phone_uncheck'),
            trigger: "blur",
          },
        ],
        password:
            [{required: true, message: window.vm.$t('login_and_register_page.input_password'), trigger: "blur"}],
        vcode:
            [{
              required: true,
              message: window.vm.$t('login_and_register_page.input_verification_code'),
              trigger: "blur"
            }],
        subscribe:
            [
              {
                required: true,
                message: window.vm.$t('login_and_register_page.read_terms'),
                trigger: "change",
              },
            ],
      }
    },
  },

  data() {

    return {
      height: "",
      active: 1,
      seconds: 0,
      realNumber: '',
      emailForm: {
        email: "",
        password: "",
        againPassword: "",
        subscribe: "",
      },
      phoneForm: {
        phone: "",
        realNumber: "",
        password: "",
        vcode: "",
        subscribe: "",
      },
    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
  },
  methods: {
    takeCode() {
      console.log(this.phoneForm);
      this.$refs.phoneForm.validateField("phone", (valid) => {
        if (!valid) {
          this.$api
              .sendCode({
                phone: this.phoneForm.realNumber,
                type: 1,
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success(window.vm.$t('login_and_register_page.send_success'));
                  this.seconds = 60;
                  let timer = setInterval(() => {
                    this.seconds = this.seconds - 1;
                    if (this.seconds === 0) {
                      clearInterval(timer);
                    }
                  }, 1000);
                }
              });
        }
      });
    },
    showRealNumber(number) {
      this.realNumber = number
      this.phoneForm.realNumber = number;
      if (number.startsWith('+86')) {
        number = number.substring(3);
      }
      //console.log(this.realNumber)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const Request =
              formName === "emailForm"
                  ? this.$api.registeredByEmail
                  : this.$api.registeredByPhone;
          Request(this[formName])
              .then((res) => {
                if (res.code === 0) {
                  this.$message({
                    type: "success",
                    message: window.vm.$t('login_and_register_page.register_success'),
                  });
                  this.$store.commit("setTicket", res.data.ticket);
                  return this.$api.getUserId({ticket: res.data.ticket});
                }
              })
              .then((res) => {
                if (res && res.code === 0) {
                  return this.$api.getClientInfo({id: res.data.userId});
                }
              })
              .then((res) => {
                if (res && res.code === 0) {
                  this.$store.commit("setUserInfo", res.data);
                  this.$message({
                    type: "success",
                    message: window.vm.$t('login_and_register_page.login_success'),
                    onClose: () => {
                      if (window.sessionStorage.getItem("isCompletion")) {
                        let query = JSON.parse(window.sessionStorage.getItem("isCompletion"));
                        this.$router.push({
                          name: "OrderPage",
                          query: query,
                        });
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                }
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-box {
    width: 500px;
    height: 722px;
    background-color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .el-form-item__content {
      position: relative;
    }

    ::v-deep .el-input-group--append .el-input__inner {
      border-right: 0px !important;
    }

    ::v-deep .el-input-group__append {
      background-color: #fff !important;
      border-left: 0px !important;
    }

    ::v-deep .el-checkbox__label {
      font-weight: 300;

      .el-link {
        font-weight: 300;
      }
    }

    .append {
      position: absolute;
      bottom: 4px;
      border: 0px;
      right: 1px;
      height: 34px;

      &:hover {
        color: #409eff;
        background-color: #fff;
      }
    }

    img {
      width: 240px;
    }

    .text-box {
      width: 330px;
      text-align: center;
      margin: 0 auto;

      .title {
        margin-top: 34px;
        font-size: 30px;
      }

      .sub-title {
        font-size: 12px;
      }
    }

    .tabs-box {
      height: 50px;
      line-height: 50px;
      width: 330px;
      margin: 30px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      position: relative;

      span {
        width: 50%;
        cursor: pointer;

        &.active {
          color: #4aabf2;
        }
      }

      .border-line {
        position: absolute;
        width: 50%;
        bottom: 0;
        height: 2px;
        z-index: 2;
        background-color: #4aabf2;
        transition: left 0.3s;
      }
    }

    .el-form {
      margin: 20px auto 0;
    }
  }
}
</style>