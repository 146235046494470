var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "login-box", style: { height: _vm.height } }, [
          _c("img", {
            staticStyle: { width: "600px" },
            attrs: { src: "https://www.achencybe.com/img/pc/login/5-1.jpg" },
          }),
          _c(
            "div",
            { staticClass: "form-box" },
            [
              _c("img", {
                attrs: { src: "https://www.achencybe.com/img/pc/logo_1.png" },
              }),
              _c("div", { staticClass: "text-box" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("register_page.register_title"))),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("register_page.register_logon"))),
                ]),
              ]),
              _c("div", { staticClass: "tabs-box" }, [
                _c(
                  "span",
                  {
                    class: { active: _vm.active === 0 },
                    on: {
                      click: function ($event) {
                        _vm.active = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("register_page.mail_register")))]
                ),
                _c(
                  "span",
                  {
                    class: { active: _vm.active === 1 },
                    on: {
                      click: function ($event) {
                        _vm.active = 1
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("register_page.phone_register")))]
                ),
                _c("span", {
                  staticClass: "border-line",
                  style: { left: _vm.active === 0 ? 0 : "50%" },
                }),
              ]),
              _vm.active === 1
                ? _c(
                    "el-form",
                    {
                      key: "phoneForm",
                      ref: "phoneForm",
                      staticStyle: { width: "330px" },
                      attrs: { rules: _vm.phoneRules, model: _vm.phoneForm },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone" } },
                        [
                          _c("zui-country-phone", {
                            attrs: {
                              sendSms: true,
                              placeholder: _vm.$t(
                                "register_page.login_phone_placeholder"
                              ),
                              "limit-seconds": _vm.seconds,
                            },
                            on: {
                              listenToChildEvent: _vm.showRealNumber,
                              listenSendSms: _vm.takeCode,
                            },
                            model: {
                              value: _vm.phoneForm.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneForm, "phone", $$v)
                              },
                              expression: "phoneForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "password",
                              placeholder: _vm.$t(
                                "register_page.login_mail_password_placeholder"
                              ),
                            },
                            model: {
                              value: _vm.phoneForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneForm, "password", $$v)
                              },
                              expression: "phoneForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "vcode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "login_and_register_page.verification_code_placeholder"
                              ),
                            },
                            model: {
                              value: _vm.phoneForm.vcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneForm, "vcode", $$v)
                              },
                              expression: "phoneForm.vcode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "subscribe" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.phoneForm.subscribe,
                                callback: function ($$v) {
                                  _vm.$set(_vm.phoneForm, "subscribe", $$v)
                                },
                                expression: "phoneForm.subscribe",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register_page.subscribe")) + " "
                              ),
                              _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-top": "-5px" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$router.push({
                                        name: "clausePage",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("register_page.subscribe_link")
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("phoneForm")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register_page.submit")) + " "
                              ),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({ name: "LoginPage" })
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register_page.login")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.active === 0
                ? _c(
                    "el-form",
                    {
                      key: "emailForm",
                      ref: "emailForm",
                      staticStyle: { width: "330px" },
                      attrs: { rules: _vm.emailRules, model: _vm.emailForm },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "register_page.login_mail_address_placeholder"
                              ),
                            },
                            model: {
                              value: _vm.emailForm.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.emailForm, "email", $$v)
                              },
                              expression: "emailForm.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "password",
                              placeholder: _vm.$t(
                                "register_page.login_mail_password_placeholder"
                              ),
                            },
                            model: {
                              value: _vm.emailForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.emailForm, "password", $$v)
                              },
                              expression: "emailForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "againPassword" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "password",
                              placeholder: _vm.$t(
                                "register_page.login_mail_password_placeholder_again"
                              ),
                            },
                            model: {
                              value: _vm.emailForm.againPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.emailForm, "againPassword", $$v)
                              },
                              expression: "emailForm.againPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "subscribe" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.emailForm.subscribe,
                                callback: function ($$v) {
                                  _vm.$set(_vm.emailForm, "subscribe", $$v)
                                },
                                expression: "emailForm.subscribe",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register_page.subscribe")) + " "
                              ),
                              _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-top": "-5px" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$router.push({
                                        name: "clausePage",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("register_page.subscribe_link")
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("emailForm")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register_page.submit")) + " "
                              ),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({ name: "LoginPage" })
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register_page.login")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }