import { render, staticRenderFns } from "./RegisteredPage.vue?vue&type=template&id=e50512fc&scoped=true&"
import script from "./RegisteredPage.vue?vue&type=script&lang=js&"
export * from "./RegisteredPage.vue?vue&type=script&lang=js&"
import style0 from "./RegisteredPage.vue?vue&type=style&index=0&id=e50512fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e50512fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matengyu/个人/bwm/CarModifyWeb/sellgit/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e50512fc')) {
      api.createRecord('e50512fc', component.options)
    } else {
      api.reload('e50512fc', component.options)
    }
    module.hot.accept("./RegisteredPage.vue?vue&type=template&id=e50512fc&scoped=true&", function () {
      api.rerender('e50512fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/public/RegisteredPage.vue"
export default component.exports